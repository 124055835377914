<script setup lang="ts">
import { MediaImageFragment, ParagraphEventTeasers } from '~/api/graphql/generated'
import { useEventsNext } from '~/api/drupal'

const props = defineProps<{
  entity: ParagraphEventTeasers
}>()

const image = computed(() => {
  return props.entity?.fieldMobileImage?.entity || props.entity?.fieldImage?.entity
    ? {
        small: props.entity?.fieldMobileImage?.entity as MediaImageFragment,
        large: props.entity?.fieldImage?.entity as MediaImageFragment,
      }
    : undefined
})

const infoEventTypeIds = computed(() => {
  return [props.entity?.fieldInfoEventType?.entity?.tid].filter(Boolean).map((item) => item.toString())
})

const { items } = await useEventsNext(infoEventTypeIds.value)
</script>

<template>
  <SectionSpecial
    :title="entity?.fieldTitle || ''"
    :sub-title="entity?.fieldDescription?.processed || ''"
    :image="image"
  >
    <h3 v-animate="{ animation: 'vertical-reveal' }">{{ entity.fieldEventItemsTitle }}</h3>
    <LinkListLarge>
      <template #default="{ isHovered }">
        <LinkListLargeItemEvent
          v-for="item in items"
          :key="item.id"
          v-animate="{ animation: 'vertical-reveal' }"
          :date="item.date"
          :title="item.title"
          :location="item.location"
          :link="item.link"
          :dim="isHovered"
        />
      </template>
    </LinkListLarge>
  </SectionSpecial>
</template>
<style scoped lang="scss"></style>
